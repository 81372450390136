//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MnemonicList from "./MnemonicList.vue";
import LandingPagePopUp from "@/views/LandingPage/LandingPagePopUp.vue";
import { mnemonicGenerate } from "@polkadot/util-crypto";
export default {
  name: "GenerateAccount",
  components: {
    LandingPagePopUp,
    MnemonicList
  },
  data: () => ({
    mnemonic: "",
    mnemonicCollection: [],
    snackbar: false,
    timeout: 2000
  }),
  mounted() {
    this.mnemonic = mnemonicGenerate();
    const mnemonicCollection = this.mnemonic.split(" ");
    for (let i = 1; i <= mnemonicCollection.length; i++) {
      this.mnemonicCollection.push(mnemonicCollection[i - 1]);
    }
  },
  methods: {
    previous() {
      this.$router.push({
        name: "generate-account"
      });
    },
    verifyMnemonic() {
      this.$router.push({
        name: "verify-mnemonic",
        params: {
          mnemonic: this.mnemonic,
          mnemonicCollection: this.mnemonicCollection
        }
      });
    },
    copyText() {
      navigator.clipboard.writeText(this.mnemonic);
      this.snackbar = true;
    }
  }
};